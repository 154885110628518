module.exports = [{
      plugin: require('/Users/simonkor/work/twopennycode.com/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/simonkor/work/twopennycode.com/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://twopennycode.com"},
    },{
      plugin: require('/Users/simonkor/work/twopennycode.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
